import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AudioPlayer = makeShortcode("AudioPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "citta",
      "style": {
        "position": "relative"
      }
    }}>{`Citta`}<a parentName="h1" {...{
        "href": "#citta",
        "aria-label": "citta permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`The terms "citta" and "dhamma", which form the last two pillars are a little more difficult to translate than body, breath, or feeling. "Citta" means something more like heart-mind: it is certainly not just an intellectual sense, but one that is - as always - embodied, incarnate, spirited.`}</p>
    <p>{`We offer here three different guided sessions on various aspects of `}<em parentName="p">{`citta`}</em>{`.`}</p>
    <h2 {...{
      "id": "attention",
      "style": {
        "position": "relative"
      }
    }}>{`Attention`}<a parentName="h2" {...{
        "href": "#attention",
        "aria-label": "attention permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/meditation_marlon_3.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "heart-mind",
      "style": {
        "position": "relative"
      }
    }}>{`Heart-Mind`}<a parentName="h2" {...{
        "href": "#heart-mind",
        "aria-label": "heart mind permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/meditation_with_marlon_4.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "awareness",
      "style": {
        "position": "relative"
      }
    }}>{`Awareness`}<a parentName="h2" {...{
        "href": "#awareness",
        "aria-label": "awareness permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/marlon_meditation_5.mp3" mdxType="AudioPlayer" />
    <p>{`We also invite you to listen to `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=RZ0YeBIOaFw"
      }}>{`Mindfulness of Mind with Joseph Goldtein`}</a>{` for another, more detaile dperspective of some of the ground covered above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      